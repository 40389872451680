<template>
	<div>
		<v-card flat>
			<v-card-title
				class="text-h6 text-md-h5 pt-2 pt-md-4 pb-2 pb-md-4 titleColor"
			>
				機材詳細
				<v-spacer></v-spacer>

				<v-btn icon @click="$store.commit('set_dialogInfo_return_info', false)">
					<v-icon color="white">mdi-close-circle</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<v-data-table
					v-if="$vuetify.breakpoint.mdAndUp"
					:headers="headers"
					:items="ListInfo"
					:items-per-page="10"
					item-key="id+name"
					elevation="0"
					:sort-by="['maker']"
					:sort-desc="false"
					:footer-props="{ 'items-per-page-text': '' }"
				>
				</v-data-table>
				<v-simple-table v-if="!$vuetify.breakpoint.mdAndUp">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">
									Model
								</th>
								<th class="text-left">
									User
								</th>
								<th class="text-left">
									Quantity
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in ListInfo" :key="item.name">
								<td>{{ item.model }}</td>
								<td>{{ item.report_check }}</td>
								<td>{{ item.quantity }}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	export default {
		name: "ReturnInfo",
		props: { ListInfo: Array },
		components: {},
		methods: {},
		computed: {},
		data: () => ({
			headers: [
				{ text: "Model", value: "model" },
				{ text: "Manufacturer", value: "manufacturer" },
				{ text: "Cable length", value: "size" },
				{ text: "Quantity", value: "quantity", sortable: false },
				{ text: "Check user", value: "report_check", sortable: false },
			],
			ecosystem: [
				{
					text: "vuetify-loader",
					href: "https://github.com/vuetifyjs/vuetify-loader",
				},
				{
					text: "github",
					href: "https://github.com/vuetifyjs/vuetify",
				},
				{
					text: "awesome-vuetify",
					href: "https://github.com/vuetifyjs/awesome-vuetify",
				},
			],
			importantLinks: [
				{
					text: "Documentation",
					href: "https://vuetifyjs.com",
				},
				{
					text: "Chat",
					href: "https://community.vuetifyjs.com",
				},
				{
					text: "Made with Vuetify",
					href: "https://madewithvuejs.com/vuetify",
				},
				{
					text: "Twitter",
					href: "https://twitter.com/vuetifyjs",
				},
				{
					text: "Articles",
					href: "https://medium.com/vuetify",
				},
			],
			whatsNext: [
				{
					text: "Explore components",
					href: "https://vuetifyjs.com/components/api-explorer",
				},
				{
					text: "Select a layout",
					href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
				},
				{
					text: "Frequently Asked Questions",
					href:
						"https://vuetifyjs.com/getting-started/frequently-asked-questions",
				},
			],
		}),
	};
</script>

<style scoped>
	.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
	td {
		min-width: 50px !important;
	}
</style>
